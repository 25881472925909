import { Form } from "@remix-run/react";
import image from "../assets/home-bg.jpg?url";

const Home: React.FC = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute inset-0 bg-gray-600 bg-cover blur-sm"
          style={{ backgroundImage: `url(${image})` }}
        />
        <div className="absolute inset-0 bg-gray-800/50 bg-cover blur-sm" />
      </div>
      <Form
        action="/"
        method="post"
        className="relative -top-10 z-10 space-y-8 text-center text-white"
      >
        <h1 className="mb-12">
          <span className="block text-2xl font-light">Welcome to the</span>{" "}
          <b className="text-4xl font-normal">Kitchen Visualiser</b>
        </h1>
        <p className="max-w-xs rounded-sm bg-black/30 p-2 text-sm">
          Enter your email to save kitchens to the cloud, or as a guest to save
          it locally to your browser.
        </p>
        <div className="flex">
          <input
            className="block w-full border-none p-3 text-center text-gray-500"
            name="email"
            type="email"
            placeholder="Email address"
          />
          <button
            className="inline-block bg-gray-400 px-4 text-white transition-colors hover:bg-gray-600"
            type="submit"
          >
            Enter
          </button>
        </div>
        <button className="underline">Enter as Guest</button>
      </Form>
    </div>
  );
};

export default Home;
